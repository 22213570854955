
/*********** fonts *************/

@font-face {
    font-family: "BentonSansBBVA-Bold";   /*Can be any text*/
    src: local("BentonSansBBVA-Bold"),
      url("assets/fonts/BentonSansBBVA-Bold.ttf") format("ttf")
}

@font-face {
    font-family: "BentonSansBBVA-Book";   /*Can be any text*/
    src: local("BentonSansBBVA-Book"),
      url("assets/fonts/BentonSansBBVA-Book.ttf") format("ttf")
}

@font-face {
    font-family: "BentonSansBBVA-BookItalic";   /*Can be any text*/
    src: local("BentonSansBBVA-BookItalic"),
      url("assets/fonts/BentonSansBBVA-BookItalic.ttf") format("ttf")
}

@font-face {
    font-family: "BentonSansBBVA-Light";   /*Can be any text*/
    src: local("BentonSansBBVA-Light"),
      url("assets/fonts/BentonSansBBVA-Light.ttf") format("ttf")
}

@font-face {
    font-family: "BentonSansBBVA-Medium";   /*Can be any text*/
    src: local("BentonSansBBVA-Medium"),
      url("assets/fonts/BentonSansBBVA-Medium.ttf") format("ttf")
}

@font-face {
    font-family: "BentonSansBBVA-MediumItalic";   /*Can be any text*/
    src: local("BentonSansBBVA-MediumItalic"),
      url("assets/fonts/BentonSansBBVA-MediumItalic.ttf") format("ttf")
}

/*lato **************/


@font-face {
  font-family: "Lato";   /*Can be any text*/
  src: local("Lato-Black"),
    url("assets/fonts/Lato/Lato-Black.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-Black";   /*Can be any text*/
  src: local("Lato-Black"),
    url("assets/fonts/Lato/Lato-Black.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-BlackItalic";   /*Can be any text*/
  src: local("Lato-BlackItalic"),
    url("assets/fonts/Lato/Lato-BlackItalic.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-Bold";   /*Can be any text*/
  src: local("Lato-Bold"),
    url("assets/fonts/Lato/Lato-Bold.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-BoldItalic";   /*Can be any text*/
  src: local("Lato-BoldItalic"),
    url("assets/fonts/Lato/Lato-BoldItalic.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-Italic";   /*Can be any text*/
  src: local("Lato-Italic"),
    url("assets/fonts/Lato/Lato-Italic.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-Light";   /*Can be any text*/
  src: local("Lato-Light"),
    url("assets/fonts/Lato/Lato-Light.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-LightItalic";   /*Can be any text*/
  src: local("Lato-LightItalic"),
    url("assets/fonts/Lato/Lato-LightItalic.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-Regular";   /*Can be any text*/
  src: local("Lato-Regular"),
    url("assets/fonts/Lato/Lato-Regular.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-Thin";   /*Can be any text*/
  src: local("Lato-Thin"),
    url("assets/fonts/Lato/Lato-Thin.ttf") format("ttf")
}

@font-face {
  font-family: "Lato-ThinItalic";   /*Can be any text*/
  src: local("Lato-ThinItalic"),
    url("assets/fonts/Lato/Lato-ThinItalic.ttf") format("ttf")
}





