.custom-scroll::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
.custom-scroll::-moz-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
.custom-scroll::-o-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
.custom-scroll::-khtml-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
.custom-scroll::-ms-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}

.custom-scroll::-webkit-scrollbar-track {
    background: #002E7B;        /* color of the tracking area */
}
.custom-scroll::-moz-scrollbar-track {
    background: #002E7B;        /* color of the tracking area */
}
.custom-scroll::-o-scrollbar-track {
    background: #002E7B;        /* color of the tracking area */
}
.custom-scroll::-khtml-scrollbar-track {
    background: #002E7B;        /* color of the tracking area */
}
.custom-scroll::-ms-scrollbar-track {
    background: #002E7B;        /* color of the tracking area */
}


.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #1973B8;    /* color of the scroll thumb */
    border-radius: 0px;       /* roundness of the scroll thumb */
    border: 3px solid transparent;  /* creates padding around scroll thumb */
}

.custom-scroll::-moz-scrollbar-thumb {
    background-color: #1973B8;    /* color of the scroll thumb */
    border-radius: 0px;       /* roundness of the scroll thumb */
    border: 3px solid transparent;  /* creates padding around scroll thumb */
}
.custom-scroll::-o-scrollbar-thumb {
    background-color: #1973B8;    /* color of the scroll thumb */
    border-radius: 0px;       /* roundness of the scroll thumb */
    border: 3px solid transparent;  /* creates padding around scroll thumb */
}
.custom-scroll::-khtml-scrollbar-thumb {
    background-color: #1973B8;    /* color of the scroll thumb */
    border-radius: 0px;       /* roundness of the scroll thumb */
    border: 3px solid transparent;  /* creates padding around scroll thumb */
}
.custom-scroll::-ms-scrollbar-thumb {
    background-color: #1973B8;    /* color of the scroll thumb */
    border-radius: 0px;       /* roundness of the scroll thumb */
    border: 3px solid transparent;  /* creates padding around scroll thumb */
}